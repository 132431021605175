/**
 *  定义的常量数据
 *
 */

export const emojiPath = 'https://api.dadaoapp.com/home/emoji/'
// export const emojiPath = 'https://images.slodon.cn/java/bbc/mobile/emoji/'

export const emoji = [
    {
        title: '微笑',
        src: 'emo_01.png'
    },
    {
        title: '愉快',
        src: 'emo_02.png'
    },
    {
        title: '呲牙',
        src: 'emo_03.png'
    },
    {
        title: '笑脸',
        src: 'emo_04.png',
    },
    {
        title: '眯眼笑',
        src: 'emo_05.png'
    },
    {
        title: "吐舌",
        src: "emo_06.png"
    },
    {
        title: "偷笑",
        src: "emo_07.png"
    },
    {
        title: "幸福",
        src: "emo_08.png"
    },
    {
        title: "酷",
        src: "emo_09.png"
    },
    {
        title: "流汗",
        src: "emo_10.png"
    },
    {
        title: "流汗笑",
        src: "emo_11.png"
    },
    {
        title: "流泪",
        src: "emo_12.png"
    },
    {
        title: "累",
        src: "emo_13.png"
    },
    {
        title: "思考",
        src: "emo_14.png"
    },
    {
        title: "可怜",
        src: "emo_15.png"
    },
    {
        title: "飞吻",
        src: "emo_16.png"
    },
    {
        title: "天使",
        src: "emo_17.png"
    },
    {
        title: "花痴",
        src: "emo_18.png"
    },
    {
        title: "笑哭",
        src: "emo_19.png"
    },
    {
        title: "请求",
        src: "emo_20.png"
    },
    {
        title: "无表情",
        src: "emo_21.png"
    },
    {
        title: "融化",
        src: "emo_22.png"
    },
    {
        title: "不开心",
        src: "emo_23.png"
    },
    {
        title: "仔细",
        src: "emo_24.png"
    },
    {
        title: "翻白眼",
        src: "emo_25.png"
    },
    {
        title: "痛苦",
        src: "emo_26.png"
    },
    {
        title: "难过",
        src: "emo_27.png"
    },
    {
        title: "惊讶",
        src: "emo_28.png"
    },
    {
        title: "冒汗",
        src: "emo_29.png"
    },
    {
        title: "太阳",
        src: "emo_30.png"
    },
    {
        title: "晕",
        src: "emo_31.png"
    },
    {
        title: "伤心",
        src: "emo_32.png"
    },
    {
        title: "生气",
        src: "emo_33.png"
    },
    {
        title: "抱歉",
        src: "emo_34.png"
    },
    {
        title: "轻松",
        src: "emo_35.png"
    },
    {
        title: "害怕",
        src: "emo_36.png"
    },
    {
        title: "失望",
        src: "emo_37.png"
    },
    {
        title: "亲亲",
        src: "emo_38.png"
    },
    {
        title: "瞌睡",
        src: "emo_39.png"
    },
    {
        title: "愤怒",
        src: "emo_40.png"
    },
    {
        title: "晕头转向",
        src: "emo_41.png"
    },
    {
        title: "庆祝",
        src: "emo_42.png"
    },
    {
        title: "无语",
        src: "emo_43.png"
    },
    {
        title: "唉",
        src: "emo_44.png"
    },
    {
        title: "泪奔",
        src: "emo_45.png"
    },
    {
        title: "肌肉",
        src: "emo_46.png"
    },
    {
        title: "点赞",
        src: "emo_47.png"
    },
    {
        title: "拜托",
        src: "emo_48.png"
    },
    {
        title: "耶",
        src: "emo_49.png"
    },
    {
        title: "手掌",
        src: "emo_50.png"
    },
    {
        title: "鼓掌",
        src: "emo_51.png"
    },
    {
        title: "张开双手",
        src: "emo_52.png"
    },
    {
        title: "OK",
        src: "emo_53.png"
    },
    {
        title: "握手",
        src: "emo_54.png"
    },
    {
        title: "爱心",
        src: "emo_55.png"
    },
    {
        title: "心碎",
        src: "emo_56.png"
    },
    {
        title: "禁止",
        src: "emo_57.png"
    },
    {
        title: "可",
        src: "emo_58.png"
    },
    {
        title: "凋谢",
        src: "emo_59.png"
    },
    {
        title: "红包",
        src: "emo_60.png"
    }
]

