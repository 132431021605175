<template>
	<div class="sld_chat_left">
		<div class="tab_group" v-if="isSuper == true && showTab">
			<div class="tabs" v-if="identity === 'admin'">
				<div :class="{ tab1: true, on: 1 == tabIndex }" @click="changeTab(1)">平台</div>
				<div :class="{ tab2: true, on: 2 == tabIndex }" @click="changeTab(2)">工作室</div>
			</div>
			<div class="select">
				<el-select
					class="custom-input"
					v-if="tabIndex === 2"
					style="width: 100%"
					v-model="curStore"
					placeholder="请选择工作室"
					filterable
					@change="changeStore"
				>
					<el-option v-for="(item, index) in storeList" :key="index" :label="item.storeName" :value="item.storeId"> </el-option>
				</el-select>
				<el-select
					class="custom-input"
					style="width: 100%"
					v-model="curService"
					placeholder="请选择客服"
					filterable
					:disabled="serviceList.length === 0"
					@change="resetChatList"
				>
					<el-option v-for="(item, index) in serviceList" :key="index" :label="item.vendorName" :value="item.vendorId"> </el-option>
				</el-select>
			</div>
		</div>

		<div class="sld_chat_box" :class="{ border_top_none: !is_chat_room }">
			<div class="top">
				<el-input class="custom-input" v-model="inputMemberName" @input="handleSearch" placeholder="搜索" prefix-icon="Search" clearable />
			</div>
			<div class="list_wrap" id="chatLeftNav" style="height: 0">
				<div class="list_con">
					<el-scrollbar id="leftScroll" ref="chat_left_ref" @scroll="scroll">
						<div v-if="chatList.list.length" v-infinite-scroll="load" :infinite-scroll-distance="10">
							<div
								class="list_section"
								:class="{ active: item.memberId == curMemberId }"
								v-for="(item, index) in chatList.list"
								:key="index"
								id="processing"
								@click="
									switchMember({
										memberId: item.memberId,
										memberName: item.memberName,
										storeId: item.storeId,
									})
								"
							>
								<div class="list_item">
									<div class="header_img">
										<img :src="item.memberAvatar" />
									</div>
									<div class="list_text">
										<p class="list_item_title">{{ item.memberName }}</p>
										<p class="msg_new">{{ item.showContent }}</p>
									</div>
									<div class="delete_icon" v-if="is_chat_room">
										<i v-if="item.receiveMsgNumber" class="msg_icon">{{ item.receiveMsgNumber > 99 ? '99+' : item.receiveMsgNumber }}</i>
										<div class="del_icon" @click.stop>
											<el-popconfirm
												:title="L['确定结束与该会员的聊天吗？']"
												@confirm="closeChatMember(item)"
												:confirmButtonText="L['确定']"
												:cancelButtonText="L['取消']"
												popper-class="el-popover grey_popper"
											>
												<template #reference>
													<img src="@/assets/service/delete.png" alt="" />
												</template>
											</el-popconfirm>
										</div>
									</div>
								</div>
							</div>
							<loadingState v-if="loadState == 'first_loading' || chatList.list.length > 0" :state="loadState" />
						</div>
						<div class="empty_data_left" v-if="!chatList.list.length > 0">
							<p>{{ L['暂无数据'] }}</p>
						</div>
					</el-scrollbar>
				</div>
			</div>
			<div
				class="info"
				:class="{ active: curMemberId === 1 }"
				v-if="showAdmin && identity !== 'admin'"
				@click="switchMember({ memberId: 1, memberName: '大道客服' })"
			>
				<div class="header_img">
					<img :src="adminInfo.admin_logo" alt="" />
				</div>
				<div class="text">
					<p>
						<span>{{ adminInfo.adminName }}</span>
					</p>
					<p class="online">{{ adminInfo.showContent || '联系客服' }}</p>
					<div class="unReadNum" v-if="adminInfo.receiveMsgNumber">
						<span class="msg_icon">{{ adminInfo.receiveMsgNumber > 99 ? '99+' : adminInfo.receiveMsgNumber }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, getCurrentInstance, reactive, onMounted, inject } from 'vue';
import { defaultAdminId } from '@/utils/config';
import { debounce } from 'lodash';
import { useStore } from 'vuex';
import loadingState from '@/components/loadingState';

export default {
	name: 'chatLeftList',
	components: {
		loadingState,
	},
	props: {
		connectBaseData: {
			type: Object,
			default: () => {},
		},
		showAdmin: {
			type: Boolean,
			default: true,
		},
		showTab: {
			type: Boolean,
			default: true,
		},
		is_chat_room: {
			type: Boolean,
			default: false,
		},
		// 是否工作室与平台聊天
		is_studio_room: {
			type: Boolean,
			default: false,
		},
	},
	beforeCreate() {
		if (this.is_chat_room) {
			this.sockets.subscribe('contact_store_change', (e) => this.socketContact(e, true));
			if (!this.is_studio_room) {
				this.sockets.subscribe('contact_change', this.socketContact);
			}
			this.sockets.subscribe('unread_num_change', (e) => {
				let id = e.memberId;
				// id为1的时候需要特殊处理
				if (id == 1) {
					if (this.identity !== 'admin') {
						// 如果正在打开这个会话，则不更新
						this.adminInfo.receiveMsgNumber = e.unreadNum;
					} else {
						id = e.vendorId;
					}
				}
				let tmp_data = this.chatList.list.find((item) => item.memberId == id);
				if (tmp_data) {
					tmp_data.receiveMsgNumber = e.unreadNum;
				}
			});
		}
	},
	beforeUnmount() {
		if (this.is_chat_room) {
			this.$socket.emit('store_change_room', { memberId: '', ...this.connectData });
			this.$socket.emit('vendor_change_room', { memberId: '', ...this.connectData });
			this.sockets.unsubscribe('contact_store_change');
			this.sockets.unsubscribe('contact_change');
			this.sockets.unsubscribe('unread_num_change');
		}
	},
	setup(props, { emit }) {
		const { proxy } = getCurrentInstance();
		const connectData = reactive(props.connectBaseData);
		const store = useStore();
		const tabIndex = ref(1);
		const storeInfo = ref({});
		const pageSize = 10;
		const current = ref(1);
		const curMemberId = ref(''); //当前聊天会员的id
		const minMsgId = ref(''); //当前消息的最小id
		const chatList = reactive({ list: [] }); //最近联系人列表
		const loadState = ref('');
		const chat_left_ref = ref('');
		const isScroll = ref(false);
		const hasMore = ref(true);
		const clientHeight = ref(0);
		const L = proxy.$getCurLanguage();
		const adminLogo = require('@/assets/adminLogo.png');
		const identity = localStorage.getItem('identity');
		const isSuper = Boolean(+localStorage.getItem('isSuper') || +localStorage.getItem('isStoreAdmin'));
		const adminName = localStorage.adminName;
		const admin_logo = ref('');
		const adminInfo = inject('adminInfo');
		// 获取平台发送的信息
		const getAdminInfo = () => {
			proxy.$get('v3/helpdesk/seller/chat/platform').then((res) => {
				if (res.state == 200) {
					if (res.data?.msgContent) {
						try {
							res.data = formatMsg(res.data);
							adminInfo.showContent = res.data.showContent;
							adminInfo.receiveMsgNumber = res.data.receiveMsgNumber;
						} catch (error) {}
					}
				}
			});
		};
		//获取最近联系人
		const getChatList = () => {
			const list_url_name = props.is_studio_room ? 'storeChatList' : 'list';
			const member_params = {
				storeId: curStore.value,
				vendorId: props.is_chat_room ? connectData.userId : curService.value,
				msgId: minMsgId.value,
				memberName: inputMemberName.value,
			};
			const studio_params = {
				msgId: minMsgId.value,
				storeName: inputMemberName.value,
			};
			const params = props.is_studio_room ? studio_params : member_params;
			if (props.is_chat_room) {
				delete params.storeId;
				delete params.vendorId;
			}

			params.pageSize = pageSize;
			params.current = current.value;

			proxy.$get(`v3/helpdesk/${identity}/chat/${list_url_name}`, params).then((res) => {
				if (res.state == 200) {
					if (props.is_studio_room) {
						res.data = res.data.map((e) => {
							return {
								...e,
								memberName: e.storeName,
								memberId: e.vendorId,
								memberAvatar: e.vendorAvatar,
							};
						});
					}
					if (minMsgId.value) {
						chatList.list = chatList.list.concat(res.data);
					} else {
						chatList.list = res.data;
					}
					formatMsgContent();
					if (chatList.list.length > 0) {
						minMsgId.value = chatList.list[chatList.list.length - 1].msgId;
					}
					hasMore.value = res.data.length < 10 ? false : true;
					if (hasMore.value) {
						loadState.value = 'allow_loading_more';
					} else {
						loadState.value = 'no_more_data';
					}
				}
			});
		};
		// 重置分页
		const resetPage = () => {
			chatList.list = [];
			minMsgId.value = '';
			inputMemberName.value = '';
			current.value = 1;
		};
		// 搜索聊天记录
		const inputMemberName = ref('');
		const handleSearch = debounce(() => {
			minMsgId.value = '';
			getChatList();
		}, 300);
		//格式化聊天内容，方便列表展示
		const formatMsgContent = () => {
			chatList.list.map((item) => {
				item = formatMsg(item);
			});
		};
		const formatMsg = (item) => {
			let reg = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;
			let reg4 = /(<\/?div.*?>)|(<\/?br.*?>)|(<\/?span.*?>)/g;
			if (typeof item.msgContent == 'string') {
				try {
					item.msgContent = JSON.parse(item.msgContent);
				} catch (error) {
					item.msgContent = item.msgContent;
				}
			}
			//1.text(文本) 2.img(图片) 3.goods(商品) 4.order(订单)用户
			if (item.msgType == 1) {
				if (reg.test(item.msgContent.content)) {
					item.msgContent.content = item.msgContent.content.replace(reg, '[表情]');
					item.showContent = item.msgContent.content;
				} else {
					item.showContent = item.msgContent.content;
				}

				if (reg4.test(item.msgContent.content)) {
					item.msgContent.content = item.msgContent.content.replace(reg4, '');
					item.showContent = item.msgContent.content;
				} else {
					item.showContent = item.msgContent.content;
				}
			} else if (item.msgType == 2) {
				item.showContent = '[图片]';
			} else if (item.msgType == 6) {
				item.showContent = '[视频]';
			} else if (item.msgType == 3) {
				item.showContent = '[商品]';
			} else if (item.msgType == 4) {
				item.showContent = '[订单]';
			}
			return item;
		};
		// 格式化对象
		const formatObj = (e) => {
			// memberAvatar，memberId左侧
			// vendorAvatar，vendorId 右侧
			if (e.platformId) {
				// 1为平台客服,2为工作室客服
				if (identity == 'admin') {
					// 如果是平台管理员，根据userType改变用户类型和其他属性
					if (e.userType === 1) {
						e.userType = 2;
					} else {
						e.userType = 1;
					}
					e.memberAvatar = e.vendorAvatar;
					e.memberName = e.storeName;
					e.memberId = e.vendorId;
					e.vendorAvatar = e.platformAvatar;
					e.vendorId = e.platformId;
				} else {
					e.memberAvatar = e.platformAvatar;
					e.memberId = e.platformId;
				}
			}
			return e;
		};
		//切换会员事件
		const switchMember = ({ memberId, memberName, storeId }) => {
			if (curMemberId.value === memberId) return;
			storeId = props.is_studio_room ? storeId : curStore.value;
			if (!connectData || Object.keys(connectData).length) {
				if (memberId !== '') {
					const changeEmit = memberId === defaultAdminId || props.is_studio_room ? 'store_change_room' : 'vendor_change_room';
					proxy.$socket.emit(changeEmit, { memberId, ...connectData, storeId });
				}
			}
			curMemberId.value = memberId;
			emit('switchMember', {
				memberId,
				memberName,
				storeId,
				vendorId: props.is_chat_room ? connectData.userId : curService.value,
			});
		};
		const socketContact = (e, isStudio) => {
			e = { ...e };
			e = formatMsg(e);
			if (isStudio && identity !== 'admin' && e.memberId === defaultAdminId) {
				// 处理特殊情况，当前登录用户为工作室客服，并且聊天对象是平台的时候
				if (curMemberId.value != defaultAdminId) {
					adminInfo.receiveMsgNumber = e.receiveMsgNumber;
				}
				adminInfo.showContent = e.showContent;
				return;
			}
			if (isStudio && !props.is_studio_room) return;
			// 更新消息
			let findIndex = chatList.list.findIndex((item) => item.memberId == e.memberId);
			if (findIndex >= 0) {
				// 防闪烁
				if (e.memberId === curMemberId.value) {
					e.receiveMsgNumber = chatList.list[findIndex].receiveMsgNumber;
				}
				// 去除，置顶
				chatList.list.splice(findIndex, 1);
			}
			chatList.list.unshift(e);
		};
		//关闭与会员的聊天事件
		const closeChatMember = (member) => {
			const event = props.is_studio_room ? 'store_remove_contact' : 'vendor_remove_contact';
			const params = props.is_studio_room ? { storeId: member.storeId, vendorId: member.memberId } : { memberId: member.memberId };
			proxy.$socket.emit(event, { ...connectData, ...params });
			chatList.list = chatList.list.filter((item) => item.memberId != member.memberId);
			if (curMemberId.value == member.memberId) {
				if (chatList.list.length > 0) {
					//该会员与当前聊天的会员是同一个，需要先移除会员再切换
					switchMember(chatList.list[0]);
				} else {
					switchMember({ memberId: -1, memberName: '', storeId: '' });
				}
			}
		};

		// 向下滚动至底部加载数据
		const load = () => {
			if (hasMore.value) {
				isScroll.value = true;
				getChatList();
			}
		};

		// 消息提示音
		const play = () => {
			let audioElement = document.createElement('audio');
			let voice = require('@/assets/voice/msg.mp3');
			audioElement.setAttribute('src', voice);
			audioElement.setAttribute('autoplay', 'autoplay');
		};
		const curService = ref(''); //聊天记录中，当前选择的聊天客服对象
		const curStore = ref(connectData.storeId); //聊天记录中，当前选择的聊天客服对象
		const storeList = ref([]); //可选的店铺列表
		const serviceList = ref([]); //可选的客服列表
		const changeTab = async (index) => {
			if (index == tabIndex.value) return;
			tabIndex.value = index;
			curService.value = '';
			curStore.value = '';
			serviceList.value = [];
			resetPage();
			if (index == 1) {
				getServiceList();
			}
		};
		const resetChatList = (vendorId) => {
			resetPage();
			getChatList();
		};
		const changeStore = (storeId) => {
			getServiceList();
		};
		// 获取店铺列表
		const getStoreList = async () => {
			await proxy.$get(`v3/helpdesk/admin/chat/storeList`).then((res) => {
				if (res.state == 200) {
					storeList.value = res.data.filter((e) => e.storeId !== 0);
				}
			});
		};
		// 获取客服列表
		const getServiceList = async () => {
			serviceList.value = [];
			curService.value = '';
			const param = {
				storeId: curStore.value,
			};
			const api_url = identity === 'admin' ? 'v3/helpdesk/admin/chat/vendorList' : 'v3/seller/seller/vendor/keFuList';
			await proxy.$get(api_url, param).then((res) => {
				if (res.state == 200) {
					serviceList.value = res.data;
					curService.value = res.data[res.data.length - 1]?.vendorId || '';
					resetPage();
					getChatList();
				}
			});
		};
		const cur_scroll_y = ref('');
		//滚动监听
		const scroll = debounce(async (e) => {
			cur_scroll_y.value = e.scrollTop;
		}, 300);
		onMounted(async () => {
			// TODO【MD】admin
			chat_left_ref.value = proxy.$refs.chat_window;

			setTimeout(() => {
				if (identity == 'admin') {
					admin_logo.value = localStorage.admin_logo;
				} else {
					storeInfo.value = store.state.storeInfo;
				}
			}, 300);
			if (props.showTab) {
				identity == 'admin' && (await getStoreList());
				await getServiceList();
			} else {
				identity == 'seller' && getAdminInfo();
				await getChatList();
			}
		});

		const emitParentData = (data) => {
			emit('switchMember', {
				memberId: data.memberId,
				memberName: data.memberName,
				storeId: curStore.value,
				vendorId: props.is_chat_room ? connectData.userId : curService.value,
			});
		};

		return {
			defaultAdminId,
			tabIndex,
			changeTab,
			chatList,
			storeInfo,
			formatMsgContent,
			curMemberId,
			switchMember,
			closeChatMember,
			load,
			loadState,
			emitParentData,
			connectData,
			clientHeight,
			L,
			play,
			adminName,
			adminLogo,
			identity,
			isSuper,
			admin_logo,
			adminInfo,
			handleSearch,
			inputMemberName,
			serviceList,
			storeList,
			curService,
			curStore,
			changeStore,
			resetChatList,
			chat_left_ref,
			scroll,
			cur_scroll_y,
			socketContact,
		};
	},
};
</script>

<style lang="scss" scoped>
.sld_chat_left {
	border-radius: 13px;
	display: flex;
	flex-direction: column;
	margin-right: 22px;
	width: 290px;
	flex-shrink: 0;
	box-shadow: 2px 6px 12px 0px rgba(143, 141, 143, 0.3);
	.tab_group {
		z-index: 2;
		.select {
			background-color: #fff;
			border: 1px solid #b8b8b8;
			border-top: none;
			border-bottom-color: #fff;
			padding: 10px 23px 0 15px;
			> *:not(:last-of-type) {
				margin-bottom: 10px;
			}
		}
		.tabs {
			display: flex;
			width: 100%;
			border-bottom: 1px solid #b8b8b8;
			div[class^='tab'] {
				font-size: 16px;
				line-height: 20px;
				color: #767676;
				background: #e7e7e7;
				height: 43px;
				line-height: 43px;
				cursor: pointer;
				text-align: center;
				flex: 1;
				border: 1px solid #b8b8b8;
				border-radius: 10px 10px 0 0;
				transform: translateY(1px);
				&.on {
					color: #000;
					background: #fff;
					border-bottom-color: transparent;
				}
				&:not(:last-of-type) {
					margin-right: 12px;
				}
			}
		}
	}

	.sld_chat_box {
		background-color: #fff;
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;
		border: 1px solid #b8b8b8;
		border-radius: 10px;
		&.border_top_none {
			border-top: none;
			border-radius: 0 0 10px 10px;
		}
		.top {
			padding: 10px 23px 10px 15px;
			border-bottom: 1px solid #eeeeee;
		}
		.info {
			&:hover {
				background-color: #f5f5f5;
			}
			&.active {
				background-color: #eeeeee;
			}
			transition: background-color 0.2s;
			display: flex;
			padding: 10px 25px;
			border-top: 1px solid #eeeeee;
			cursor: pointer;
			.header_img {
				border-radius: 100%;
				width: 40px;
				height: 40px;
				overflow: hidden;
				border: 1px solid #b8b8b8;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			.text {
				margin-left: 12px;
				position: relative;
				flex: 1;
				.unReadNum {
					position: absolute;
					right: 0;
					top: 0;
					text-align: center;
					font-style: normal;
					margin-right: 6px;
					display: inline-block;
					padding: 2px 5px;
					height: 20px;
					line-height: 20px;
					min-width: 20px;
					padding: 0 5px;
					background-color: #505050;
					color: #fff;
					font-size: 12px;
					border-radius: 20px;
					transform: scale(0.9);
				}
				p {
					line-height: 20px;
					display: flex;
					align-items: center;
					max-width: 200px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&.online {
						color: #8d8d99;
					}
				}
			}
		}
		.list_wrap {
			flex: 1;
			width: 100%;
			padding: 0 5px 45px;
			background: #fff;

			.tab {
				height: 46px;

				span {
					display: inline-block;
					text-align: center;
					height: 52px;
					padding: 17px 35px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					border-bottom: 1px solid #f7f7f7;
					cursor: pointer;

					i {
						font-style: normal;
					}
				}

				.tabChosen {
					border-bottom: 2px solid #0871ff;

					i {
						width: 95px;
						height: 17px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #0b72f2;
					}
				}
			}

			.list_con {
				height: 100%;
				.list_section {
					padding: 10px 12px;
					margin: 0 18px 0 10px;
					border-bottom: 1px solid #eeeeee;
					border-radius: 5px;
					transition: background-color 0.2s;
					cursor: pointer;
					&:hover {
						background-color: #f5f5f5;
					}
					&.active {
						background-color: #eeeeee;
					}
				}

				.list_item {
					display: flex;
					align-items: center;
					position: relative;

					.header_img {
						flex-shrink: 0;
						overflow: hidden;
						border-radius: 100%;
						width: 40px;
						height: 40px;
						& > img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					&:hover {
						.delete_icon {
							.del_icon {
								opacity: 1;
							}
						}
					}

					.list_text {
						margin-left: 10px;
						margin-right: 25px;
						overflow: hidden;

						.list_item_title {
							line-height: 22px;
							margin-right: 20px;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							color: #17171a;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.msg_new {
							line-height: 18px;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #666666;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: #a7a6ae;
						}
					}

					.delete_icon {
						position: absolute;
						right: 0px;
						display: flex;

						i {
							text-align: center;
							font-style: normal;
							margin-right: 6px;
							display: inline-block;
							padding: 2px 5px;
							height: 20px;
							line-height: 20px;
							min-width: 20px;
							padding: 0 5px;
							background-color: #505050;
							color: #fff;
							font-size: 12px;
							border-radius: 20px;
							transform: scale(0.9);
						}

						.del_icon {
							opacity: 0;
							transition: opacity 0.2s;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}

.empty_data_left {
	height: 300px;
	padding-top: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		width: 80px;
	}

	p {
		margin-top: 15px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #d5d5d5;
	}
}
</style>
