// 封装视频处理逻辑
async function processVideo(video, canvas) {
	try {
		// 设置video的尺寸，确保能够获取整个视频帧
		video.width = video.videoWidth;
		video.height = video.videoHeight;

		// 在视频准备好后，将currentTime设置为0尝试获取第一帧

		await new Promise((resolve) => {
			video.addEventListener('seeked', resolve, { once: true });
			video
				.play()
				.catch(() => {})
				.then(() => {
					video.currentTime = 0;
					video.pause();
				});
		});

		// 使用canvas绘制当前帧
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;

		const ctx = canvas.getContext('2d');
		ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

		// 从canvas导出图片，例如导出为base64的图片
		const mimeType = 'image/png';
		const imgUrl = canvas.toDataURL(mimeType);

		// 释放视频URL对象
		URL.revokeObjectURL(video.src);

		return { url: imgUrl, width: video.videoWidth, height: video.videoHeight };
	} catch (err) {
		throw err;
	}
}
export function getCoverAndUploadVideo(videoFile) {
	if (!videoFile) {
		return Promise.reject('Please upload a video file.');
	}

	const video = document.createElement('video');
	const url = URL.createObjectURL(videoFile);
	video.src = url;
	video.muted = true;

	// 解决微信浏览器下，ios上传视频，出现的问题

	return new Promise(async (rs, rj) => {
		try {
			const canvas = document.createElement('canvas');
			const imageData = await processVideo(video, canvas);
			rs(imageData);
			// 从DOM中移除video元素，避免内存泄露
			video.parentNode.removeChild(video);
		} catch (err) {
			rj(err);
		} finally {
			// 释放对象URL
			URL.revokeObjectURL(url);
		}
	});
}
