<template>
	<div class="chat_container" v-loading="pageLoading">
		<div class="chat_box">
			<div class="top" v-if="curMember.memberName">
				<p class="top_title">{{ curMember.chatSourceUrl }} {{ curMember.memberName }}</p>
				<p class="top_num" v-if="curMember.storeIdList">({{ curMember.storeIdList.length }})</p>
			</div>
			<div class="filter_wrap" v-if="curMember.memberName && hasFilter">
				<div class="left">
					<div class="item">
						<span class="label">搜索内容 </span>
						<el-input v-model="filterParams.msgContent" style="min-width: 130px" placeholder="搜索" clearable />
					</div>
					<div class="item">
						<span class="label">聊天日期 </span>
						<el-date-picker
							v-model="filterParams.dateRange"
							type="daterange"
							range-separator="-"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							:disabled-date="disabledDate"
							clearable
						>
						</el-date-picker>
					</div>
				</div>
				<div class="right">
					<div class="custom-btn filter_btn" @click="setQuery">搜索</div>
				</div>
			</div>
			<div class="chat_window_wrap">
				<el-scrollbar @scroll="scroll" ref="chat_window">
					<div class="scroll_box" ref="chat_list_box">
						<div class="loading" v-show="hasMore && curMember.memberName && !curMember.storeIdList">
							<span> 向上滚动浏览更多 </span>
						</div>
						<template v-if="msgList.list.length > 0 || curMember.storeIdList.length > 0">
							<div v-for="(item, index) in msgList.list" :key="index">
								<div v-if="index == 0 || (index > 0 && $isShowTime(msgList.list[index - 1].addTime, item.addTime))" class="time_line">
									{{ $formatChatTime(item.addTime) }}
								</div>
								<!-- 会员发送的信息start -->
								<template v-if="item.userType == 1">
									<div class="chat_ladder user">
										<div class="user_avatar left">
											<img :src="item.memberAvatar" />
										</div>
										<div class="content_right">
											<span class="content_text text_type" v-html="item.msgContent.content" v-if="item.msgType == 1"></span>
											<div class="content_text image_type" v-else-if="item.msgType == 2">
												<el-image :src="item.msgContent.pic" :preview-src-list="[item.msgContent.pic]">
													<template #placeholder>
														<div class="image-slot">
															<el-icon><Picture /></el-icon>
														</div>
													</template>
												</el-image>
											</div>
											<div class="content_text goods_type" v-else-if="item.msgType == 3">
												<div class="goods_model" @click="toDetail(item.msgContent, 'goods')">
													<img :src="item.msgContent.goodsImage" alt="" />
													<div class="goods_info">
														<div class="goods_info_title">
															{{ item.msgContent.goodsName }}
														</div>
														<div class="goods_info_bottom">
															<span>{{ L['￥'] }}{{ item.msgContent.goodsPrice }}</span>
														</div>
													</div>
												</div>
											</div>
											<div class="content_text order_type" @click="toDetail(item.msgContent, 'order')" v-else-if="item.msgType == 4">
												<div class="order_title">
													<span class="order_sn"
														>{{ L['订单号'] }}：<i>{{ item.msgContent.orderSn }}</i></span
													>
													<span class="order_time">{{ item.msgContent.createTime }}</span>
												</div>
												<div class="goods_model order_type">
													<img :src="item.msgContent.goodsImage" alt="" />
													<div class="goods_info">
														<div class="goods_info_title">
															{{ item.msgContent.goodsName }}
														</div>
														<div class="goods_info_bottom goods_info_bottom_detial">
															<span>￥{{ item.msgContent.productShowPrice ? item.msgContent.productShowPrice : item.msgContent.goodsPrice }}</span>
															<span class="goods_info_bottom_ok">{{ item.msgContent.orderStateValue }}</span>
														</div>
													</div>
												</div>
											</div>
											<div class="content_text image_type" v-else-if="item.msgType == 6">
												<div class="video_img_box">
													<div class="video_box" v-loading="item.loading">
														<img
															:src="item.msgContent.pic || item.msgContent?.video + '?x-oss-process=video/snapshot,t_0,f_jpg,w_400,h_0,m_fast'"
															alt=""
															@click="playVideo(item.msgContent.video)"
														/>
														<el-icon class="video_icon"><VideoPlay /></el-icon>
													</div>
												</div>
											</div>
										</div>
										<!-- <div
											v-if="item.msgState"
											:class="{
												msg_read: item.msgState == 1,
												msg_not_read: item.msgState == 2,
											}"
										>
											{{ item.msgState == 1 ? L['已读'] : L['未读'] }}
										</div> -->
									</div>
								</template>
								<!-- 会员发送的信息end -->

								<!-- 客服发送的消息start -->
								<template v-if="item.userType == 2">
									<div class="chat_ladder merchant">
										<div
											v-if="item.msgState"
											:class="{
												msg_read: item.msgState == 1,
												msg_not_read: item.msgState == 2,
											}"
										>
											{{ item.msgState == 1 ? L['已读'] : L['未读'] }}
										</div>
										<div class="content_right">
											<span class="content_text text_type" v-if="item.msgType == 1" v-html="item.msgContent.content"></span>
											<div class="content_text goods_type" v-if="item.msgType == 3">
												<div class="goods_model" @click="toDetail(item.msgContent, 'goods')">
													<img :src="item.msgContent.goodsImage" alt="" />
													<div class="goods_info">
														<div class="goods_info_title">
															{{ item.msgContent.goodsName }}
														</div>
														<div class="goods_info_bottom">
															<span>￥{{ item.msgContent.goodsPrice }}</span>
														</div>
													</div>
												</div>
											</div>
											<div class="content_text order_type" v-if="item.msgType == 4" @click="toDetail(item.msgContent, 'order')">
												<div class="order_title">
													<span class="order_sn"
														>{{ L['订单号'] }}：<i>{{ item.msgContent.orderSn }}</i></span
													>
													<span class="order_time">{{ item.msgContent.createTime }}</span>
												</div>
												<div class="goods_model order_type">
													<img :src="item.msgContent.goodsImage" alt="" />
													<div class="goods_info">
														<div class="goods_info_title">
															{{ item.msgContent.goodsName }}
														</div>
														<div class="goods_info_bottom goods_info_bottom_detial">
															<span>￥{{ item.msgContent.goodsPrice }}</span>
															<span class="goods_info_bottom_ok">{{ item.msgContent.orderStateValue }}</span>
														</div>
													</div>
												</div>
											</div>
											<div class="content_text image_type" v-if="item.msgType == 2">
												<el-image :src="item.msgContent.pic" :preview-src-list="[item.msgContent.pic]" v-loading="item.loading">
													<template #placeholder>
														<div class="image-slot">
															<!-- <i class="el-icon-picture-outline"></i> -->
															<el-icon><Picture /></el-icon>
														</div>
													</template>
												</el-image>
											</div>
											<div class="content_text image_type" v-if="item.msgType == 6">
												<div class="video_img_box">
													<div class="video_box" v-loading="item.loading">
														<img
															:src="item.msgContent?.pic || item.msgContent?.video + '?x-oss-process=video/snapshot,t_0,f_jpg,w_400,h_0,m_fast'"
															alt=""
															@click="playVideo(item.msgContent.video)"
														/>
														<el-icon class="video_icon"><VideoPlay /></el-icon>
													</div>
												</div>
											</div>
										</div>
										<div class="user_avatar">
											<img :src="item.vendorAvatar || admin_logo" alt="" />
										</div>
									</div>
								</template>
								<!-- 客服发送的消息end -->
							</div>
						</template>
						<div class="empty_data_left" v-else>
							<!-- <img src="@/assets/goods/empty_data.png" alt="" /> -->
							<p>{{ L['暂无数据'] }}</p>
						</div>
					</div>
					<!-- 这里加个div标签是防止一开始出现‘can not read property 0 of null’的错误 -->
				</el-scrollbar>
			</div>
			<!-- 聊天输入框start -->
			<!-- 聊天输入框end -->
		</div>
		<div class="chat_input" id="chat_input" v-if="curMember.memberName && is_chat_room">
			<div class="chat_tool">
				<!-- 表情start -->
				<el-popover placement="top-start" :width="278" trigger="click">
					<template #reference>
						<a class="face_ex">
							<img src="@/assets/service/face_ex.png" alt="" />
							<!-- <i class="iconfont icon-biaoqing"></i> -->
						</a>
					</template>
					<div class="emoji_wrap">
						<el-scrollbar>
							<div class="emoji_s">
								<div v-for="(e, i) in emojis" :key="i" class="emoji_item" @click="transEmoji(e.src)">
									<img :src="require('@/assets/emoji/' + e.src)" alt="" />
								</div>
							</div>
						</el-scrollbar>
					</div>
				</el-popover>
				<!-- 表情end -->

				<!-- 图片start -->
				<label for="image">
					<img src="@/assets/service/pic.png" alt="" />
				</label>
				<input type="file" id="image" @change="getImage" ref="imageFile" accept="image/*" />
				<!-- 图片end -->

				<!-- 视频start -->
				<label for="video">
					<img src="@/assets/service/video.png" alt="" />
				</label>
				<input type="file" id="video" @change="getVideo" ref="videoFile" accept="video/*" />
				<!-- 视频end -->

				<!-- 快捷语恢复start -->
				<el-popover placement="top-start" :width="350" trigger="click" ref="popFlag" data-class="custom-popover">
					<template #reference>
						<label for="quick_reply">
							<img src="@/assets/service/quick_reply.png" alt="" @click="getQuickReplyActive" />
						</label>
					</template>
					<div class="pop_header">
						<p>{{ L['常用语快捷回复'] }}</p>
					</div>
					<div class="pop_list" v-if="quickReplyList && quickReplyList.list && quickReplyList.list.length > 0">
						<el-scrollbar>
							<div class="pop_item" v-for="(item, index) in quickReplyList.list" :key="index" @click="sendQuick(item.msgContent)">
								{{ item.msgContent }}
							</div>
						</el-scrollbar>
					</div>
					<div class="empty_quick" v-if="!quickReplyList.list.length > 0">
						<!-- <img src="@/assets/goods/empty_data.png" alt="" /> -->
						<p>{{ L['暂未设置快捷回复'] }}</p>
					</div>
				</el-popover>
				<!-- 快捷语end -->

				<!-- 转接start -->
				<label for="vendor" v-if="curMember.memberId !== defaultAdminId && !is_studio_room">
					<img src="@/assets/service/tran_touch.png" alt="" @click="chatTransfer" />
				</label>
				<!-- 转接end -->
			</div>
			<div class="chat_input_area">
				<el-scrollbar>
					<pre
						name="sendBox"
						contenteditable="true"
						class="send-textarea"
						@blur="preBlur"
						@keydown.enter.prevent="keyDownPre"
						@input="preInput"
						ref="sendBox"
						@paste="listenPaste"
					></pre>
				</el-scrollbar>
			</div>
			<div class="input_button custom-btn white full" @click="send()">发送（Enter）<i class="iconfont"></i></div>
		</div>
	</div>
	<el-dialog v-model="videoDialog" custom-class="custom-dialog" title="预览视频" width="800px" @closed="curVideo = false">
		<div class="video-box">
			<video :src="curVideo" controls class="play_video"></video>
		</div>
	</el-dialog>
	<!-- 客服转接框start -->
	<el-dialog title="客服列表" custom-class="custom-dialog" v-model="vendor_visible" width="400px">
		<div class="transfer_box">
			<el-scrollbar>
				<div class="transfer_list">
					<div class="transfer_item" v-for="(venItem, venIndex) in vendor_list.list" :key="venIndex">
						<div class="left">
							<img :src="admin_logo" alt="" />
							<div class="text_con">
								<p class="service_name">{{ venItem.vendorName }}</p>
								<p class="service_state">{{ L['在线'] }}</p>
							</div>
						</div>
						<div class="custom-btn small" @click="vendorTrans(venItem.vendorId)">切换</div>
					</div>
					<div class="empty_trans" v-show="!vendor_list.list.length > 0">
						<!-- <img src="@/assets/goods/empty_data.png" alt="" /> -->
						<p>{{ L['暂无在线客服'] }}</p>
					</div>
				</div>
			</el-scrollbar>
		</div>
	</el-dialog>
</template>

<script>
import { onMounted, getCurrentInstance, reactive, ref, watch, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import { emoji, emojiPath } from '@/utils/data.js';
import { defaultAdminId } from '@/utils/config';
import { getCoverAndUploadVideo } from '@/utils/uploadLocal.js';
import { debounce } from 'lodash';
import { computed } from 'vue';
import { inject } from 'vue';

export default {
	name: 'chatWindows',
	emits: ['closeChatMember'],
	props: {
		hasFilter: {
			type: Boolean,
			default: false,
		},
		is_chat_room: {
			type: Boolean,
			default: false,
		},
		is_studio_room: {
			type: Boolean,
			default: false,
		},
	},
	deactivated() {},
	beforeCreate() {
		//监听连接成功事件
		if (this.is_chat_room) {
			this.sockets.subscribe('get_room_info', (data) => {
				this.socketInfo.data = data;
			});
			if (!this.is_studio_room) {
				this.sockets.subscribe('get_read_msg', this.socketSetRead);
				this.sockets.subscribe('contact_change', this.get_send);
			}
			this.sockets.subscribe('contact_store_change', this.get_send);
			this.sockets.subscribe('get_read_store_msg', this.socketSetRead);
			this.sockets.subscribe('get_member_read_all', () => {
				this.msgList.list.map((item) => {
					item.msgState = 1;
				});
			});
			this.sockets.subscribe('get_member_source_url', (data) => {
				if (data.sourceUrl) {
					this.curMember.chatSourceUrl = decodeURIComponent(data.sourceUrl);
				} else {
					this.curMember.chatSourceUrl = '';
				}
			});
		}
	},
	beforeUnmount() {
		if (this.is_chat_room) {
			this.sockets.unsubscribe('get_room_info');
			this.sockets.unsubscribe('contact_change');
			this.sockets.unsubscribe('contact_store_change');
			this.sockets.unsubscribe('get_member_source_url');
			this.sockets.unsubscribe('get_read_msg');
			this.sockets.unsubscribe('get_read_store_msg');
			this.sockets.unsubscribe('get_member_read_all');
		}
	},
	setup(props, { emit }) {
		const is_chat_room = reactive(props.is_chat_room);
		const connectBaseData = {
			storeId: localStorage.identity == 'seller' ? localStorage.storeId : 0,
			userId: localStorage.identity == 'seller' ? localStorage.vendorId : localStorage.adminId,
			role: 2,
		};
		const socketInfo = reactive({ data: {} }); //socket连接成功返回的房间信息
		const msgList = reactive({ list: [] }); //聊天列表
		const minMsgId = ref(''); //当前消息聊天记录列表里的最小消息id
		const dateRange = ref(''); //日期起止事件
		const filterParams = reactive({
			msgContent: '',
			dateRange: [],
		});
		const identity = localStorage.getItem('identity');
		const admin_logo = computed(() => {
			if (identity == 'admin') {
				return admin_info.admin_logo;
			} else {
				return store_info.data?.storeLogoUrl;
			}
		});
		const admin_info = inject('adminInfo');
		const store_info = inject('storeInfo');
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const curMember = reactive({
			memberName: '',
			memberId: '',
			storeIdList: '',
			chatSourceUrl: '',
		});
		const chat_window_ref = ref('');
		const param = reactive({
			current: 1,
			memberId: '',
			startTime: '',
			endTime: '',
			msgContent: '',
			vendorId: '',
			vendorChoose: '',
			storeIdList: '',
		});
		const popFlag = ref(false);
		const loading = ref(false);
		const pageLoading = ref(false);
		const hasMore = ref(true);
		const scrollTop = ref(false);
		// input
		const textarea = ref('');
		const quickReplyList = reactive({
			list: [],
		});
		const is_studio_platform = computed(() => {
			return props.is_studio_room || curMember.memberId === defaultAdminId;
		});
		const socketEmit = computed(() => {
			return {
				send: is_studio_platform.value ? 'send_store_msg' : 'send_msg',
				get_send: is_studio_platform.value ? 'get_send_store_msg' : 'get_send_msg',
				read: is_studio_platform.value ? 'read_store_msg' : 'read_msg',
				get_read: is_studio_platform.value ? 'get_read_store_msg' : 'get_read_msg',
			};
		});
		const disabledDate = (time) => {
			return time.getTime() > Date.now();
		};
		const setCurrent = (n) => {
			param.current = n;
		};

		const clearFilter = () => {
			param.current = 1;
			param.startTime = '';
			param.endTime = '';
			param.msgContent = '';
			filterParams.msgContent = '';
			filterParams.dateRange = [];
			list_scroll_y = 0;
		};
		const preInput = (e) => {
			var re = /<[^img][^>]+>/g;
			textarea.value = e.target.innerHTML.replace(re, '');
		};
		// 将光标移动到保存的末尾
		const moveToSavedMsg = () => {
			const editableElement = proxy.$refs.sendBox;
			if (!editableElement) return;
			const range = document.createRange(); // 创建一个新的Range
			const selection = window.getSelection(); // 获取当前的Selection对象
			range.selectNodeContents(editableElement); // 选择editableElement的全部内容
			range.collapse(false); // 将Range折叠到尾部，false表示折叠到结束位置
			// 清除现有的selection，然后添加新的range
			selection.removeAllRanges();
			selection.addRange(range);
			// 使editableElement成为焦点
			editableElement.focus();
		};
		let savedRange = '';
		const preBlur = (e) => {
			// 保存选区
			const selection = window.getSelection();
			if (selection.rangeCount > 0) {
				savedRange = selection.getRangeAt(0);
			}
		};
		const emojis = reactive(emoji);
		const transEmoji = (src) => {
			const imgSrc = emojiPath + '' + src;
			const imgTag = document.createElement('img');
			imgTag.src = imgSrc;
			imgTag.className = 'emoji_item_chat';
			if (savedRange !== null) {
				// 设置选区到之前保存的位置
				const selection = window.getSelection();
				selection.removeAllRanges();
				selection.addRange(savedRange);
				// 插入图片到这个选区
				savedRange.insertNode(imgTag);
				// 维护光标在图片后
				savedRange.setStartAfter(imgTag);
				// 清除当前选区并设置新的选区，使光标位置更新到图片后面
				selection.removeAllRanges();
				selection.addRange(savedRange);
			} else {
				// 如果没有记录选区，直接插入到内容末尾
				proxy.$refs.sendBox.appendChild(imgTag);
			}
			textarea.value = proxy.$refs.sendBox.innerHTML;
		};
		// 平台客服与工作室客服构建参数
		const getMsgParams = () => {
			let params = {};
			if (is_studio_platform.value) {
				if (identity === 'admin') {
					params.storeId = socketInfo.data.storeId;
					params.vendorId = socketInfo.data.memberId;
					params.role = 1;
				} else {
				}
			}
			return params;
		};
		const get_send = (data) => {
			console.log(data,'contact')
			if (data.memberId == socketInfo.data.memberId && data.vendorId == socketInfo.data.vendorId) {
				// 如果是对方发送的消息
				if (data.userType == 1) {
					setRead([data.msgId]);
				}
				preMsg(data);
				proxy.$nextTick(() => {
					chat_window_ref.value?.setScrollTop(10000);
				});
			}
		};
		// 处理接收到的消息
		const preMsg = (data) => {
			data = { ...data };
			try {
				data.msgContent = JSON.parse(data.msgContent);
			} catch (error) {}
			if (data.msgType == 2) {
				const targetIndex = msgList.list.findIndex((e) => e.msgContent.uuid === data.msgContent.uuid);
				if (targetIndex > 0) {
					const url = msgList.list[targetIndex]?.msgContent?.pic;
					URL.revokeObjectURL(url);
					msgList.list[targetIndex] = data;
				} else {
					msgList.list.push(data);
				}
			} else if (data.msgType === 6) {
				const targetIndex = msgList.list.findIndex((e) => e.msgContent.uuid === data.msgContent.uuid);
				if (targetIndex > 0) {
					msgList.list[targetIndex] = data;
				} else {
					msgList.list.push(data);
				}
			} else {
				msgList.list.push(data);
			}
		};
		// 格式化聊天记录列表数据
		const formatLog = (e) => {
			// memberAvatar，memberId左侧
			// vendorAvatar，vendorId 右侧
			if (is_studio_platform.value) {
				// 1为平台客服,2为工作室客服
				if (identity == 'admin') {
					// 如果是平台管理员，根据userType改变用户类型和其他属性
					if (e.userType === 1) {
						e.userType = 2;
					} else {
						e.userType = 1;
					}
					e.memberAvatar = e.vendorAvatar;
					e.memberId = e.vendorId;
					e.vendorAvatar = e.platformAvatar;
					e.vendorId = e.platformId;
				} else {
					e.memberAvatar = e.platformAvatar;
					e.memberId = e.platformId;
				}
			}
			return e;
		};
		const keyDownPre = (e) => {
			if (e.ctrlKey === false) return send();
		};
		//发送消息
		const send = () => {
			if (pageLoading.value) return false;
			let contentTest = textarea.value.replace(/&nbsp;|\s+/g, ''); //没有内容的话直接结束
			if (!contentTest.trim() && !contentTest) {
				return false;
			}
			if (contentTest.length > 50000) {
				ElMessage(L['超过最大字符限制']);
				return;
			}

			let content = textarea.value; //没有内容的话直接结束
			let msgData = {};
			msgData.memberId = socketInfo.data.memberId;
			msgData.vendorId = socketInfo.data.vendorId;

			if (curMember.storeIdList) {
				// 群聊内容
				msgData.storeList = curMember.storeIdList;
				msgData.msgContent = JSON.stringify({
					content: content,
				});
				proxy.$post('v3/helpdesk/admin/chat/massSending', msgData, 'json').then((res) => {
					msgData.addTime = new Date();
					msgData.msgType = '1';
					msgData.userType = '2';
					msgData.msgContent = JSON.parse(msgData.msgContent);
					msgList.list.push(msgData);
					// 刷新左边列表
					emit('refreshLeftList');
				});
			} else {
				msgData.msg = {
					content: content,
				};
				msgData.msgType = '1';
				const msgParams = getMsgParams();
				proxy.$socket.emit(socketEmit.value.send, { ...msgData, ...connectBaseData, ...msgParams });
				console.log('🚀 ~ send ~  { ...msgData, ...connectBaseData, ...msgParams }:', { ...msgData, ...connectBaseData, ...msgParams });
			}
			textarea.value = ''; //清空输入框的内容
			proxy.$refs.sendBox.innerHTML = '';
		};
		//获取快捷回复列表
		const getQuickReplyActive = () => {
			popFlag.value = true;
			let params = {
				current: 1,
			};
			proxy.$get(`v3/helpdesk/${identity}/quick/list`, params).then((res) => {
				if (res.state == 200) {
					quickReplyList.list = res.data.list.filter((item) => item.isShow == 1);
				}
			});
		};
		//发送快捷回复
		const sendQuick = (msg) => {
			proxy.$refs.popFlag?.hide();
			popFlag.value = false;
			proxy.$refs.sendBox.innerHTML += msg;
			textarea.value += msg;
			moveToSavedMsg();
			// proxy.$socket.emit(socketEmit.value.send, { ...msgData, ...connectBaseData });
		};
		// 消息提示音
		const play = () => {
			let audioElement = document.createElement('audio');
			let voice = require('@/assets/media/msg.mp3');
			audioElement.setAttribute('src', voice);
			audioElement.setAttribute('autoplay', 'autoplay');
		};

		// 发送图片
		const getImage = (e) => {
			if (e.target.files[0]) {
				let tar_file = e.target.files[0];
				if (tar_file.type.indexOf('image') < 0) {
					ElMessage.warning(L['请选择图片类型文件']);
					return;
				}
				let msgData = {};
				msgData.memberId = socketInfo.data.memberId;
				msgData.vendorId = socketInfo.data.vendorId;
				msgData.msgType = 2;
				msgData.userType = 2;
				msgData.loading = true;
				const uuid = new Date().getTime();
				msgData.msgContent = {
					pic: URL.createObjectURL(e.target.files[0]),
					uuid,
				};
				msgList.list.push(msgData);
				// 滚动到底部
				nextTick(() => {
					chat_window_ref.value?.setScrollTop(10000);
				});
				proxy
					.$post(
						'v3/oss/common/upload',
						{
							source: 'goods',
							file: e.target.files[0],
						},
						'form'
					)
					.then((res) => {
						if (res.state == 200) {
							proxy.$refs.imageFile.value = '';
							msgData.msg = {
								pic: res.data.url,
								width: res.data.width,
								height: res.data.height,
								uuid,
							};
							const msgParams = getMsgParams();
							proxy.$socket.emit(socketEmit.value.send, { ...msgData, ...connectBaseData, ...msgParams });
							console.log('🚀 ~ .then ~ { ...msgData, ...connectBaseData, ...msgParams }:', { ...msgData, ...connectBaseData, ...msgParams });
						}
					});
			}
		};

		// 发送视频
		const getVideo = async (e) => {
			if (e.target.files[0]) {
				let tar_file = e.target.files[0];
				if (tar_file.type.indexOf('video') < 0) {
					ElMessage.warning(L['请选择视频类型文件']);
					return;
				}
				let msgData = {};
				msgData.memberId = socketInfo.data.memberId;
				msgData.vendorId = socketInfo.data.vendorId;
				msgData.msgType = 6;
				msgData.userType = 2;
				msgData.loading = true;
				const { url, width, height } = await getCoverAndUploadVideo(e.target.files[0]);
				const uuid = new Date().getTime();
				msgData.msgContent = {
					pic: url,
					uuid,
				};
				msgList.list.push(msgData);
				// 滚动到底部
				nextTick(() => {
					chat_window_ref.value?.setScrollTop(10000);
				});
				proxy
					.$post(
						'v3/oss/common/upload',
						{
							source: 'video',
							file: e.target.files[0],
						},
						'form'
					)
					.then((res) => {
						if (res.state == 200) {
							proxy.$refs.videoFile.value = '';
							msgData.msg = {
								video: res.data.url,
								pic: res.data.url + '?x-oss-process=video/snapshot,t_0,f_jpg,w_400,h_0,m_fast',
								width: width || res.data.width,
								height: height || res.data.height,
								uuid,
							};
							const msgParams = getMsgParams();
							proxy.$socket.emit(socketEmit.value.send, { ...msgData, ...connectBaseData, ...msgParams });
						}
					});
			}
		};
		const videoDialog = ref(false);
		const curVideo = ref('');
		const playVideo = (url) => {
			if (url) {
				videoDialog.value = true;
				curVideo.value = url;
			}
		};
		const listenPaste = (e) => {
			if (e.clipboardData && e.clipboardData.items) {
				let items = e.clipboardData.items || [];
				let file = null;
				if (items && items.length) {
					for (let i = 0; i < items.length; i++) {
						if (items[i].type.indexOf('image') !== -1) {
							file = items[i].getAsFile();
						}
					}
				}
				// 获取文件之后就可以上传服务器或者其他操作啦
				if (file) {
					let obj = {
						target: {
							files: [file],
						},
					};
					proxy
						.$confirm(L['是否要发送剪切板的图片?'], L['提示'], {
							confirmButtonText: L['确认'],
							cancelButtonText: L['取消'],
						})
						.then(() => {
							getImage(obj);
						});
				} else {
					textFormat(e);
				}
			}
		};

		//复制其他地方的文本需要这样的特殊处理,不能简单的innerHTML = xxxxx
		const textFormat = (e) => {
			e.preventDefault();
			var text;
			var clp = (e.originalEvent || e).clipboardData;
			if (clp === undefined || clp === null) {
				text = window.clipboardData.getData('text') || '';
				if (text !== '') {
					if (window.getSelection) {
						var newNode = document.createElement('span');
						newNode.innerHTML = text;
						window.getSelection().getRangeAt(0).insertNode(newNode);
					} else {
						document.selection.createRange().pasteHTML(text);
					}
				}
			} else {
				text = clp.getData('text/plain') || '';
				if (text !== '') {
					document.execCommand('insertText', false, text);
				}
			}
		};

		const clearMsgContent = () => {
			param.current = 1;
			param.msgContent = '';
		};

		const clearDate = () => {
			param.current = 1;
			param.endTime = '';
			param.startTime = '';
		};
		const setQuery = () => {
			list_scroll_y = 0;
			const { msgContent, dateRange } = filterParams;
			param.current = 1;
			param.msgContent = msgContent;
			minMsgId.value = '';
			let startTime, endTime;
			if (dateRange && dateRange.length) {
				if (dateRange[0]) {
					startTime =
						[new Date(dateRange[0]).getFullYear(), new Date(dateRange[0]).getMonth() + 1, new Date(dateRange[0]).getDate()]
							.map((item) => (item.toString().length >= 2 ? item : '0' + item))
							.join('-') + ' 00:00:00';
				}

				if (dateRange[1]) {
					endTime =
						[new Date(dateRange[1]).getFullYear(), new Date(dateRange[1]).getMonth() + 1, new Date(dateRange[1]).getDate()]
							.map((item) => (item.toString().length >= 2 ? item : '0' + item))
							.join('-') + ' 23:59:59';
				}
			}
			param.startTime = startTime;
			param.endTime = endTime;
			getList();
		};

		const clearMsgList = () => {
			hasMore.value = false;
			msgList.list = [];
		};
		/**
		 * 设置聊天窗口的ID及相关参数，用于初始化或切换聊天对象。
		 * @param {string} memberId 用户ID
		 * @param {string} vendorId 供应商ID
		 * @param {string} storeId 店铺ID
		 * @param {string} memberName 用户名
		 * @param {Array} storeIdList 店铺ID列表，用于群发场景
		 */
		const setId = async ({ memberId, vendorId, storeId, memberName, storeIdList = '' }) => {
			chat_window_ref.value = proxy.$refs.chat_window;
			clearFilter();
			param.vendorId = vendorId;
			param.storeId = storeId || 0;
			param.memberId = memberId;
			curMember.storeIdList = storeIdList;
			curMember.memberName = memberName;
			curMember.memberId = +memberId; //memberId===0则为平台客服
			// 单聊界面
			if (!storeIdList) {
				minMsgId.value = '';
				// 如果id为空则清空列表
				if (memberId === '') {
					msgList.list = [];
					return;
				}
				const timeout = setTimeout(() => {
					pageLoading.value = true;
				}, 300);
				await getList();
				clearTimeout(timeout);
				pageLoading.value = false;
			} else {
				// 群发界面
				msgList.list = [];
			}
			nextTick(() => {
				moveToSavedMsg();
			});
		};

		// 对话列表
		const getChatLog = async (params) => {
			let url = `v3/helpdesk/${identity}/chat/msgLog`;
			// 如果是平台-工作室聊天
			if (is_studio_platform.value) {
				url = `v3/helpdesk/${identity}/chat/storeMsgLog`;
				if (identity === 'admin') {
					params = {
						storeId: params.storeId,
						vendorId: params.memberId,
					};
				}
			}
			if (minMsgId.value) {
				params.msgId = minMsgId.value;
			}
			await proxy.$post(url, params).then((res) => {
				if (res.state == 200) {
					const list = res.data.map((e) => {
						e = formatLog(e);
						try {
							e.msgContent = JSON.parse(e?.msgContent);
						} catch (error) {
							e.msgContent = e.msgContent;
						}
						return e;
					});
					if (minMsgId.value == '') {
						msgList.list = list;
					} else {
						msgList.list = list.concat(msgList.list);
					}
					if (msgList.list.length > 0) {
						minMsgId.value = msgList.list[0].msgId;
						const ids = msgList.list
							.filter((e) => {
								if (e.userType == 1 && e.msgState == 2) {
									return e;
								}
							})
							.map((e) => e.msgId);
						setRead(ids);
					}
					hasMore.value = res.data.length >= 10;
				}
			});
		};
		// 聊天记录
		const getHistoryLog = async (params) => {
			const url = `v3/helpdesk/${identity}/chat/logList`;
			await proxy.$get(url, params).then((res) => {
				if (res.state == 200) {
					const list = res.data.list.map((e) => {
						try {
							e.msgContent = JSON.parse(e?.msgContent);
						} catch (error) {
							e.msgContent = e.msgContent;
						}
						return e;
					});
					if (param.current == 1) {
						msgList.list = list;
					} else {
						msgList.list = list.concat(msgList.list);
					}
					let page = res.data.pagination;
					hasMore.value = page.current * page.pageSize < page.total;
					if (hasMore.value) {
						param.current++;
					}
				}
			});
		};

		// 获取聊天列表
		const getList = async (Y = 0) => {
			loading.value = true;

			let params = {};
			Object.keys(param).forEach((item) => {
				if (param[item] !== '') {
					params[item] = param[item];
				}
			});
			if (is_chat_room) {
				await getChatLog(params);
			} else {
				await getHistoryLog(params);
			}
			loading.value = false;

			nextTick(() => {
				if (list_scroll_y) {
					const scrollTop = proxy.$refs?.chat_list_box?.scrollHeight - list_scroll_y + Y;
					proxy.$refs.chat_window.setScrollTop(scrollTop);
				} else {
					proxy.$refs.chat_window.setScrollTop(10000);
				}
				list_scroll_y = proxy.$refs?.chat_list_box?.scrollHeight;
			});
		};
		// 修改已读状态
		const setRead = (ids) => {
			if (ids === undefined || ids.length === 0) return;
			const msgParams = getMsgParams();
			proxy.$socket.emit(socketEmit.value.read, {
				msgIds: ids.join(','),
				memberId: curMember.memberId,
				...connectBaseData,
				...msgParams,
			});
			console.log({
				msgIds: ids.join(','),
				memberId: curMember.memberId,
				...connectBaseData,
				...msgParams,
			});
		};
		// 将目标消息标记为已读
		const socketSetRead = (data) => {
			let allData = data.msgIds.split(',');
			msgList.list.map((item) => {
				if (allData.indexOf(item.msgId)) {
					item.msgState = 1;
				}
			});
		};
		let list_scroll_y = 0; //列表加载后需要滚动的高度
		const cur_scroll_y = ref('');
		//滚动监听
		const scroll = debounce(async (e) => {
			cur_scroll_y.value = e.scrollTop;
			if (e.scrollTop <= 40 && hasMore.value && !loading.value) {
				await getList(e.scrollTop);
			}
		}, 300);
		// 转接客服
		const vendor_visible = ref(false);
		const vendor_list = reactive({
			list: [],
		});
		//打开客服转接
		const chatTransfer = () => {
			vendor_visible.value = true;
			proxy.$socket.emit('pre_switch_vendor', { ...connectBaseData }, (data) => {
				vendor_list.list = data;
			});
		};
		// 点击转接客服
		const vendorTrans = (vendorId) => {
			proxy.$socket.emit('switch_vendor', { switchVendorId: vendorId, memberId: curMember.memberId, ...connectBaseData }, () => {
				//删除将该会员从最近联系人列表中删除
				emit('closeChatMember', curMember.memberId);
				ElMessage({
					showClose: true,
					message: L['转接成功'],
					type: 'success',
				});
				vendor_visible.value = false;
			});
		};
		onMounted(() => {});

		return {
			defaultAdminId,
			identity,
			L,
			scrollTop,
			msgList,
			disabledDate,
			send,
			socketInfo,
			dateRange,
			clearFilter,
			setCurrent,
			setQuery,
			getList,
			setId,
			clearMsgList,
			clearMsgContent,
			clearDate,
			getImage,
			getVideo,
			popFlag,
			quickReplyList,
			curMember,
			filterParams,
			scroll,
			loading,
			pageLoading,
			hasMore,
			preInput,
			preBlur,
			emojis,
			transEmoji,
			listenPaste,
			play,
			sendQuick,
			getQuickReplyActive,
			chat_window_ref,
			cur_scroll_y,
			admin_logo,
			preMsg,
			formatLog,
			curVideo,
			videoDialog,
			playVideo,
			socketEmit,
			admin_info,
			get_send,
			socketSetRead,
			vendor_visible,
			vendor_list,
			chatTransfer,
			vendorTrans,
			keyDownPre,
		};
	},
};
</script>

<style lang="scss" scoped>
.chat_container {
	height: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 -12px;
	padding: 0 12px;
	width: 716px;
}
.chat_box {
	flex-direction: column;
	display: flex;
	flex: 1;
	overflow: hidden;
	border: 1px solid #b8b8b8;
	background-color: #eeeeee;
	border-radius: 10px;
	.top {
		height: 50px;
		line-height: 50px;
		padding: 0 28px;
		font-size: 16px;
		border-bottom: 1px solid #e5e2e2;
		display: flex;
		.top_title {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		.top_num {
			color: #a7a6ae;
		}
	}
	.filter_wrap {
		display: flex;
		justify-content: space-between;
		padding: 12px 30px;
		border-bottom: 1px solid #e5e2e2;
		.left {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			.item {
				flex: 1;
				display: flex;
				align-items: center;
				margin-right: 20px;
				max-width: 300px;
			}

			.label {
				flex-shrink: 0;
				margin-right: 9px;
			}
		}
		.right {
			.filter_btn {
				height: 30px;
				line-height: 28px;
				min-width: 76px;
				flex: auto;
				border-color: transparent;
				box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
			}
		}
	}
	.chat_window_wrap {
		height: 0;
		flex: 1;
	}
	.loading {
		height: 40px;
		text-align: center;
		line-height: 40px;
		color: #bbb;
		font-size: 12px;
	}
	.el-scrollbar {
		padding: 0 28px;
	}

	.time_line {
		margin: 20px auto;
		line-height: 22px;
		text-align: center;
		font-size: 12px;
		font-weight: 400;
		color: $colorDark;
		width: 149px;
		height: 22px;
		border-radius: 11px;
	}

	#text_center {
		width: 589px;
		height: 104px;
		background: #ffffff;
		border-radius: 6px;

		.goods_model {
			display: flex;
			justify-content: center;
			align-content: center;
			padding-top: 10px;
			padding-left: 10px;
		}
	}

	.chat_ladder {
		display: flex;
		align-items: flex-end;
		margin-top: 20px;
		margin-bottom: 20px;
		align-items: flex-start;
		box-sizing: border-box;
		.user_avatar {
			flex-shrink: 0;
			border-radius: 6px;
			overflow: hidden;
			width: 40px;
			height: 40px;
			& > img {
				width: 100%;
				height: 100%;
			}
		}

		.content_right {
			margin-left: 10px;
			margin-right: 10px;

			p {
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #999999;
				margin-bottom: 5px;
			}

			.content_text {
				display: inline-block;
				padding: 10px 15px;
				line-height: 20px;
				border-radius: 5px;
				position: relative;
				flex: 1;
				background: #ffffff;
				font-size: 14px;
				&.order_type,
				&.goods_type {
					width: 350px;
				}
			}

			.order_type {
				cursor: pointer;

				.goods_info {
					flex: 1;
					.goods_info_title {
					}
				}

				.order_title {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					margin-bottom: 8px;
					font-size: 12px;
					.order_sn {
						color: #999999;

						i {
							font-style: normal;
							color: #666666;
						}
					}

					.order_time {
						color: #999999;
					}
				}
			}

			.goods_type {
				cursor: pointer;
			}

			.image_type {
				max-width: 250px;
				.video_img_box {
					width: 100%;
					.video_box {
						position: relative;
						display: flex;
						cursor: pointer;
						.video_icon {
							pointer-events: none;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							font-size: 60px;
							color: #ffffff;
							background-color: #66666679;
							border-radius: 100%;
						}
					}
					img {
						width: 220px;
						min-height: 50px;
						height: 100%;
					}
				}
				:deep(.img) {
					max-width: 200px;
					object-fit: contain;
				}

				.image-slot {
					width: 200px;
					height: 200px;
				}
			}

			.text_type {
				max-width: 360px;
				min-height: 40px;
				word-break: break-word;
				word-wrap: break-word;
				white-space: pre-wrap;
			}
		}
	}

	.user {
		justify-content: flex-start;

		.content_text {
			background: #ffffff;

			// border-radius: 6px;
		}
	}

	.merchant {
		padding-right: 10px;
		justify-content: flex-end;

		p {
			text-align: right;
		}

		.content_text {
			.tiny_triangle {
				position: absolute;
				right: -9px;
				width: 0;
				height: 0;
				border-right: 14px solid transparent;
				border-bottom: 13px solid #fff;
			}

			background: #d7d7d7 !important;
			// border-radius: 6px;
		}
	}
	.msg_read {
		align-self: flex-end;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: $colorDark;
	}

	.msg_not_read {
		align-self: flex-end;
		height: 100%;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: $colorDark;
		flex-shrink: 0;
	}
}
.empty_data_left {
	position: absolute;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #eeeeee;
	border-radius: 4px;
	color: #bbb;
}

.goods_model {
	display: flex;

	& > img {
		flex-shrink: 0;
		width: 84px;
		height: 84px;
		background: #707070;
		border-radius: 6px;
	}

	.goods_info {
		margin-left: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.goods_info_title {
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 18px;
			overflow: hidden;
			word-break: break-all;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			padding-right: 10px;
		}

		.goods_info_bottom {
			display: flex;
			justify-content: space-between;

			span:first-child {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #17171a;
			}

			span:nth-child(2) {
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #17171a;
			}
		}
	}
}
.chat_input {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	.chat_tool {
		width: 100%;
		height: 41px;
		display: flex;
		align-items: center;
		img {
			margin-right: 10px;
			cursor: pointer;
			width: 24px;
			height: 24px;
		}
	}

	.chat_input_area {
		background: #eeeeee;
		border: 1px solid #b8b8b8;
		border-radius: 10px;
		padding: 14px 0 14px 14px;
		height: 133px;

		.send-textarea {
			box-sizing: border-box;
			padding-right: 23px;
			width: 100%;
			min-height: 100px;
			color: #252525;
			line-height: 20px;
			font-size: 14px;
			font-family: inherit;
			word-break: break-all;
			white-space: normal;
			overflow-y: visible;
			outline: none;
		}
	}

	button {
		width: 96px;
		height: 40px;
		// background: #0e6fd7;
		background: $colorMain;
		// border-radius: 6px;
		border: none;
		color: #fff;
		background: #848484;
		font-size: 16px;
	}

	.input_button {
		margin-top: 20px;
		border: none;
		border-radius: 10px;
		box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
	}
}
.emoji_wrap {
	height: 300px;
}
.emoji_s {
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	.emoji_item {
		padding: 3px;
		margin: 2px;
		vertical-align: middle;
		width: 26px;
		height: 26px;
		box-sizing: content-box;
		transition: background 0.3s;
		cursor: pointer;
		&:hover {
			background-color: #eeeeee;
		}
		img {
			width: 26px;
			height: 26px;
			object-fit: contain;
		}
	}
}
:deep(.emoji_item_chat) {
	width: 24px;
	height: 24px;
	vertical-align: middle;
	margin: auto 1px;
	pointer-events: none;
	object-fit: contain;
}
.pop_header {
	border-radius: 10px 10px 0 0;
	height: 38px;
	background: #f3f3f4;
	display: flex;
	justify-content: space-between;
	.el-icon {
		font-size: 22px;
		line-height: 38px;
		height: 38px;
		display: inline-block;
		cursor: pointer;
	}
	p {
		margin-left: 10px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 38px;
	}

	i {
		font-size: 16px;
		margin-right: 10px;
	}
}
.empty_quick {
	height: 330px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		width: 87px;
		height: 55px;
	}

	p {
		margin-top: 11px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
	}
}
.pop_list {
	margin-top: 5px;
	height: 300px;

	.pop_item {
		padding: 8px 12px;
		border-bottom: 1px solid #e7e7e7;
		width: 100%;
		cursor: pointer;

		&:hover {
			background: #f8f8f8;
		}
	}
}
.play_video {
	width: 100%;
	max-height: 500px;
}
.transfer_box {
	padding: 20px;
	max-height: 500px;
}
.transfer_list {
	.transfer_item {
		display: flex;
		border-bottom: 1px solid #e7e7e7;
		padding-bottom: 10px;
		margin-bottom: 10px;
		.left {
			flex: 1;
			display: flex;
		}
		.text_con {
			margin: 0 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		img {
			flex-shrink: 0;
			border-radius: 2px;
			overflow: hidden;
			width: 40px;
			height: 40px;
		}
	}
}
.video-box {
	display: flex;
}
#image {
	display: none;
}

#video {
	display: none;
}
</style>
